import React from "react";
import { graphql } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";

import Layout from "../components/Layout";
import FaqContent from "../components/Faq";
import MetaComponents from "../components/seo/MetaComponents";
import BreadcrumbList from "../components/seo/BreadcrumbList";
import { urls, extendUrlWithSourceVersion } from "../constants/urls";

const Faq = () => {
    const { t } = useTranslation();
    return (
        <Layout>
            <MetaComponents title={t("page.meta.title")} description={t("page.meta.description")} />

            <FaqContent
                useH1
                shouldRenderStructuredData={true}
                ctaText="Kostenlose Beratung"
                ctaUrl={extendUrlWithSourceVersion(urls.survey)}
                hasMarginTop
            />

            <BreadcrumbList page={"faq"}></BreadcrumbList>
        </Layout>
    );
};

export default Faq;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: { ns: { in: ["header", "footer", "faq", "page.faq"] }, language: { eq: $language } }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
